import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import 'vue-multiselect/dist/vue-multiselect.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Toast)
app.use(VueSweetalert2, {
  confirmButtonColor: '#321fd',
  cancelButtonColor: '#ebedef',
})
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('v-select', vSelect)
app.component('DocsExample', DocsExample)

app.mount('#app')
