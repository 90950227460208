<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Menu
      </CDropdownHeader>
      <CDropdownItem href="/profil-saya">
        <CIcon icon="cil-user" /> Profil Saya
      </CDropdownItem>
      <CDropdownItem @click="logout" href="javascript:;">
        <CIcon icon="cil-account-logout" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/avatar.png'
import { useRouter } from 'vue-router'
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    const toast = useToast()
    const router = useRouter()
    const swal = inject('$swal')

    const logout = () => {
      swal({
        title: 'Logout',
        text: 'Apakah anda yakin ingin keluar dari aplikasi?',
        showCloseButton: true,
        confirmButtonColor: '#e55353',
        confirmButtonText: 'Keluar',
      }).then((confirm) => {
        if (confirm.value) {
          toast.warning('Anda telah keluar dari aplikasi')
          localStorage.clear()
          router.push({
            name: 'Login',
          })
        } else {
          swal.close()
        }
      })
    }

    return {
      logout,
      avatar: avatar,
      itemsCount: 42,
    }
  },
}
</script>
