import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBKEZPaE4Mznr_HEeiaIB-vv9KxK4w9ldw',
  authDomain: 'cbf-group.firebaseapp.com',
  databaseURL:
    'https://cbf-group-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'cbf-group',
  storageBucket: 'cbf-group.appspot.com',
  messagingSenderId: '69330920951',
  appId: '1:69330920951:web:e750a4e98a45052f6ebaf2',
}

export const firebaseApp = initializeApp(firebaseConfig)
