import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue'
import nav from '@/_nav.js'
import { checkAuth } from '@/_checkAuth'

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

let notifikasi = ref(null)

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  props: ['notif'],
  setup(props) {
    const route = useRoute()
    const firstRender = ref(true)

    notifikasi.value = props.notif

    onMounted(() => {
      firstRender.value = false
    })

    const renderItem = (item) => {
      if (!checkAuth(item.auth)) {
        return
      }

      if (notifikasi.value) {
        if (item.name === 'Purchase Order') {
          if (notifikasi.value.PurchaseOrder) {
            item.notif = {
              color: 'danger',
              text: 'new',
            }
          } else {
            item.notif = null
          }
        }
        if (item.name === 'Invoice') {
          if (notifikasi.value.Invoice) {
            item.notif = {
              color: 'danger',
              text: 'new',
            }
          } else {
            item.notif = null
          }
        }
        if (item.name === 'Pembayaran') {
          if (notifikasi.value.Pembayaran) {
            item.notif = {
              color: 'danger',
              text: 'new',
            }
          } else {
            item.notif = null
          }
        }
        if (item.name === 'Perbaikan Armada') {
          if (notifikasi.value.PerbaikanArmada) {
            item.notif = {
              color: 'danger',
              text: 'new',
            }
          } else {
            item.notif = null
          }
        }
      }

      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: 'nav-icon',
                name: item.icon,
              }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                    href: props.href,
                    onClick: () => props.navigate(),
                  },
                  {
                    default: () => [
                      item.icon &&
                        h(resolveComponent('CIcon'), {
                          customClassName: 'nav-icon',
                          name: item.icon,
                        }),
                      item.name,
                      item.badge &&
                        h(
                          CBadge,
                          {
                            class: 'ms-auto',
                            color: item.badge.color,
                          },
                          {
                            default: () => item.badge.text,
                          },
                        ),
                      item.notif &&
                        h(
                          CBadge,
                          {
                            class: 'ms-auto rounded-circle',
                            color: item.notif.color,
                            style: 'width: 15px; height: 15px',
                          },
                          {
                            default: () => [
                              h('span', {
                                class: 'tes',
                              }),
                            ],
                          },
                        ),
                    ],
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            },
          )
    }

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => nav.map((item) => renderItem(item)),
        },
      )
  },
  watch: {
    notif: function (newVal) {
      // watch it
      notifikasi.value = newVal
    },
  },
})
export { AppSidebarNav }
