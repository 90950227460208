import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

import DefaultLayout from '@/layouts/DefaultLayout'
import { checkAuth } from '@/_checkAuth'
import { firebaseApp } from '@/_firebaseInit'
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { getDatabase, ref as refFire, set } from 'firebase/database'
import debounce from 'lodash.debounce'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { roles: ['Approver', 'Maker'] },
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/profil-saya',
        name: 'Profil',
        meta: {
          roles: [
            'Approver',
            'Maker',
            'Maker Daerah',
            'Supervisor',
            'Supir & Mekanik',
          ],
        },
        component: () => import('@/views/profil/Index.vue'),
      },
      {
        path: '/purchase-order',
        name: 'Purchase Order',
        meta: { roles: ['Approver', 'Maker'] },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/purchase-order',
        children: [
          {
            path: '/purchase-order',
            name: 'List Purchase Order',
            component: () => import('@/views/po/Index.vue'),
          },
          {
            path: '/purchase-order/detail',
            name: 'Detail Purchase Order',
            component: () => import('@/views/po/Detail.vue'),
          },
          {
            path: '/purchase-order/tambah',
            name: 'Tambah Purchase Order',
            component: () => import('@/views/po/Add.vue'),
          },
          {
            path: '/purchase-order/edit',
            name: 'Edit Purchase Order',
            component: () => import('@/views/po/Edit.vue'),
          },
        ],
      },
      {
        path: '/delivery-order',
        name: 'Delivery Order',
        meta: { roles: ['Approver', 'Maker', 'Maker Daerah'] },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/delivery-order',
        children: [
          {
            path: '/delivery-order',
            name: 'List Delivery Order',
            component: () => import('@/views/do/Index.vue'),
          },
          {
            path: '/delivery-order/detail',
            name: 'Detail Delivery Order',
            component: () => import('@/views/do/Detail.vue'),
          },
          {
            path: '/delivery-order/tambah',
            name: 'Tambah Delivery Order',
            component: () => import('@/views/do/Add.vue'),
          },
          {
            path: '/delivery-order/edit',
            name: 'Edit Delivery Order',
            component: () => import('@/views/do/Edit.vue'),
          },
        ],
      },
      {
        path: '/barang',
        name: 'Barang',
        meta: { roles: ['Approver', 'Maker'] },
        component: () => import('@/views/barang/Index.vue'),
      },
      {
        path: '/customer',
        name: 'List Customer',
        meta: { roles: ['Approver', 'Maker'] },
        component: () => import('@/views/customer/Index.vue'),
      },
      {
        path: '/karyawan',
        name: 'Karyawan',
        meta: { roles: ['Approver', 'Maker'] },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/karyawan/akun',
        children: [
          {
            path: '/karyawan/akun',
            name: 'List Akun',
            component: () => import('@/views/karyawan/Index.vue'),
          },
          {
            path: '/karyawan/status-login',
            name: 'Status Login',
            component: () => import('@/views/karyawan/StatusLogin.vue'),
          },
          {
            path: '/karyawan/absensi',
            name: 'Absensi',
            component: () => import('@/views/karyawan/Absensi.vue'),
          },
        ],
      },
      {
        path: '/admin',
        name: 'List Admin',
        meta: { roles: ['Approver'] },
        component: () => import('@/views/admin/Index.vue'),
      },
      {
        path: '/direktur',
        name: 'Direktur',
        meta: { roles: ['Approver', 'Maker'] },
        component: () => import('@/views/direktur/Index.vue'),
      },
      {
        path: '/metode-pembayaran',
        name: 'Metode Pembayaran',
        meta: { roles: ['Approver', 'Maker'] },
        component: () => import('@/views/metode-pembayaran/Index.vue'),
      },
      {
        path: '/invoice',
        name: 'Invoice',
        meta: { roles: ['Approver', 'Maker'] },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/invoice',
        children: [
          {
            path: '/invoice',
            name: 'List Invoice',
            component: () => import('@/views/invoice/Index.vue'),
          },
          {
            path: '/invoice/detail',
            name: 'Detail Invoice',
            component: () => import('@/views/invoice/Detail.vue'),
          },
          {
            path: '/invoice/non-do/detail',
            name: 'Detail Invoice Non Do',
            component: () => import('@/views/invoice/DetailNonDo.vue'),
          },
          {
            path: '/invoice/tambah',
            name: 'Tambah Invoice',
            component: () => import('@/views/invoice/Add.vue'),
          },
          {
            path: '/invoice/non-do/tambah',
            name: 'Tambah Invoice Non Do',
            component: () => import('@/views/invoice/AddNonDo.vue'),
          },
          {
            path: '/invoice/edit',
            name: 'Edit Invoice',
            component: () => import('@/views/invoice/Edit.vue'),
          },
          {
            path: '/invoice/non-do/edit',
            name: 'Edit Invoice Non Do',
            component: () => import('@/views/invoice/EditNonDo.vue'),
          },
        ],
      },
      {
        path: '/pembayaran',
        name: 'Pembayaran',
        meta: { roles: ['Approver', 'Maker'] },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/pembayaran',
        children: [
          {
            path: '/pembayaran',
            name: 'List Pembayaran',
            component: () => import('@/views/pembayaran/Index.vue'),
          },
          {
            path: '/pembayaran/riwayat',
            name: 'Riwayat Pembayaran',
            component: () => import('@/views/pembayaran/Riwayat.vue'),
          },
        ],
      },
      {
        path: '/armada',
        name: 'List Armada',
        meta: { roles: ['Approver', 'Maker'] },
        component: () => import('@/views/armada/Index.vue'),
      },
      {
        path: '/perbaikan-armada',
        name: 'Perbaikan',
        meta: {
          roles: ['Approver', 'Supervisor', 'Supir & Mekanik'],
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/perbaikan-armada',
        children: [
          {
            path: '/perbaikan-armada/detail',
            name: 'Detail Perbaikan',
            component: () => import('@/views/perbaikan/Detail.vue'),
          },
          {
            path: '/perbaikan-armada',
            name: 'List Perbaikan',
            component: () => import('@/views/perbaikan/Index.vue'),
          },
          {
            path: '/perbaikan-armada/tambah',
            name: 'Tambah Perbaikan',
            component: () => import('@/views/perbaikan/Add.vue'),
          },
          {
            path: '/perbaikan-armada/edit',
            name: 'Edit Perbaikan',
            component: () => import('@/views/perbaikan/Edit.vue'),
          },
          {
            path: '/perbaikan-armada/verifikasi',
            name: 'Verifikasi Perbaikan',
            component: () => import('@/views/perbaikan/Verifikasi.vue'),
          },
        ],
      },
      {
        path: '/laporan',
        name: 'Laporan',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/laporan/delivery-order',
        children: [
          {
            path: '/laporan/purchase-order',
            name: 'Laporan Purchase Order',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/laporan/LaporanPo.vue'),
          },
          {
            path: '/laporan/delivery-order',
            name: 'Laporan Delivery Order',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/laporan/LaporanDo.vue'),
          },
          {
            path: '/laporan/invoice',
            name: 'Laporan Invoice',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/laporan/LaporanInvoice.vue'),
          },
          {
            path: '/laporan/pembayaran',
            name: 'Laporan Pembayaran',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/laporan/LaporanPembayaran.vue'),
          },
          {
            path: '/laporan/perbaikan-armada',
            name: 'Laporan Perbaikan Armada',
            meta: { roles: ['Approver', 'Supervisor'] },
            component: () => import('@/views/laporan/LaporanPerbaikan.vue'),
          },
          {
            path: '/laporan/akun-admin',
            name: 'Laporan Akun Admin',
            meta: { roles: ['Approver'] },
            component: () => import('@/views/laporan/LaporanAkunAdmin.vue'),
          },
          {
            path: '/laporan/akun-karyawan',
            name: 'Laporan Akun Karyawan',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/laporan/LaporanAkunKaryawan.vue'),
          },
          {
            path: '/laporan/absensi-karyawan',
            name: 'Laporan Absensi Karyawan',
            meta: { roles: ['Approver', 'Maker'] },
            component: () =>
              import('@/views/laporan/LaporanAbsensiKaryawan.vue'),
          },
          {
            path: '/laporan/customer',
            name: 'Laporan Customer',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/laporan/LaporanCustomer.vue'),
          },
          {
            path: '/laporan/armada',
            name: 'Laporan Armada',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/laporan/LaporanArmada.vue'),
          },
          {
            path: '/laporan/barang',
            name: 'Laporan Barang',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/laporan/LaporanBarang.vue'),
          },
        ],
      },
      {
        path: '/log',
        name: 'Log',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/log/delivery-order',
        children: [
          {
            path: '/log/purchase-order',
            name: 'Log Purchase Order',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/PurchaseOrder.vue'),
          },
          {
            path: '/log/delivery-order',
            name: 'Log Delivery Order',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/DeliveryOrder.vue'),
          },
          {
            path: '/log/invoice',
            name: 'Log Invoice',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/Invoice.vue'),
          },
          {
            path: '/log/pembayaran',
            name: 'Log Pembayaran',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/Pembayaran.vue'),
          },
          {
            path: '/log/perbaikan-armada',
            name: 'Log Perbaikan Armada',
            meta: { roles: ['Approver', 'Supervisor'] },
            component: () => import('@/views/log/Perbaikan.vue'),
          },
          {
            path: '/log/akun-admin',
            name: 'Log Akun Admin',
            meta: { roles: ['Approver'] },
            component: () => import('@/views/log/AkunAdmin.vue'),
          },
          {
            path: '/log/akun-karyawan',
            name: 'Log Akun Karyawan',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/AkunKaryawan.vue'),
          },
          {
            path: '/log/customer',
            name: 'Log Customer',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/Customer.vue'),
          },
          {
            path: '/log/armada',
            name: 'Log Armada',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/Armada.vue'),
          },
          {
            path: '/log/barang',
            name: 'Log Barang',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/Barang.vue'),
          },
          {
            path: '/log/metode-pembayaran',
            name: 'Log Metode Pembayaran',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/MetodePembayaran.vue'),
          },
          {
            path: '/log/direktur',
            name: 'Log Direktur',
            meta: { roles: ['Approver', 'Maker'] },
            component: () => import('@/views/log/Direktur.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: { requiresAuth: false },
    component: () => import('@/views/auth/Login.vue'),
  },
  {
    path: '/lupa-password',
    name: 'Lupa Password',
    meta: { requiresAuth: false },
    component: () => import('@/views/auth/LupaPassword.vue'),
  },
  {
    path: '/admin/reset-password/:otp',
    name: 'Reset Password Admin',
    meta: { requiresAuth: false },
    component: () => import('@/views/auth/ResetPasswordAdmin.vue'),
  },
  {
    path: '/karyawan/reset-password/:otp',
    name: 'Reset Password Admin',
    meta: { requiresAuth: false },
    component: () => import('@/views/auth/ResetPasswordKaryawan.vue'),
  },
  {
    path: '/delivery-order/cek/:kode',
    name: 'Cek Delivery Order',
    meta: { requiresAuth: false },
    component: () => import('@/views/cek-do/Index.vue'),
  },
  {
    // path: "*",
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { requiresAuth: false },
    component: () => import('@/views/404.vue'),
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('_token')

  if (!token && to.name !== 'Login' && to.meta.requiresAuth) {
    next({ name: 'Login' })
  } else if (to.name !== 'Login' && !to.meta.requiresAuth) {
    next()
  } else {
    if (token) {
      store.dispatch('auth/setToken')
      store.dispatch('auth/setData')
      const level = store.getters['auth/getData']?.Level

      //Cek Level, jika level supervisor & maker langsung ke perbaikan
      if (
        !checkAuth(to.meta.roles) &&
        ['Supervisor', 'Supir & Mekanik'].includes(level)
      ) {
        next({ path: '/perbaikan-armada' })
        return
      }

      //Cek Level, jika level Admin Daerah langsung ke DO
      if (!checkAuth(to.meta.roles) && ['Maker Daerah'].includes(level)) {
        next({ path: '/delivery-order' })
        return
      }

      //Cek Level, jika level Approver & Maker langsung ke perbaikan
      if (!checkAuth(to.meta.roles) && ['Approver', 'Maker'].includes(level)) {
        next({ path: '/dashboard' })
        return
      }
    }

    next()
  }
})

router.afterEach((to) => {
  store.commit('setLoading', false)
  const level = store.getters['auth/getData']?.Level

  if (to.path === '/purchase-order') {
    listenForNotif(level, 'PurchaseOrder')
  }
  if (to.path === '/invoice') {
    listenForNotif(level, 'Invoice')
  }

  if (to.path === '/pembayaran') {
    listenForNotif(level, 'Pembayaran')
  }

  if (to.path === '/perbaikan-armada') {
    listenForNotif(level, 'PerbaikanArmada')
  }
})

const listenForNotif = debounce((level, action) => {
  const auth = getAuth(firebaseApp)

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const db = getDatabase(firebaseApp)

      if (level) {
        // Begin Set Data To Turn Of Notification
        set(
          refFire(
            db,
            `Admin/Notifikasi/${
              level === 'Supir & Mekanik' ? 'SupirMekanik' : level
            }/${action}`,
          ),
          false,
        )
      }
    } else {
      // Begin Login
      signInWithEmailAndPassword(
        auth,
        'firebase@cbfgroup.com',
        '&firebase@cbfgroup.com&',
      )
        .then(() => {
          console.log('Login Sukses')
          // console.log(userCredential)

          // user = userCredential.user
          // console.log(user.uid)
        })
        .catch(() => {
          console.log('Login Gagal')

          // console.log(error.code)
          // console.log(error.message)
        })
      // End Login
    }
  })
}, 3000)

export default router
