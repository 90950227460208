export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Purchase Order',
    to: '/purchase-order',
    icon: 'cil-cart',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Delivery Order',
    to: '/delivery-order',
    icon: 'cil-list-rich',
    auth: ['Maker', 'Approver', 'Maker Daerah'],
  },
  {
    component: 'CNavItem',
    name: 'Invoice',
    to: '/invoice',
    icon: 'cil-notes',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Pembayaran',
    to: '/pembayaran',
    icon: 'cil-credit-card',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Armada',
    to: '/armada',
    icon: 'cil-truck',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Perbaikan Armada',
    to: '/perbaikan-armada',
    icon: 'cil-settings',
    auth: ['Supir & Mekanik', 'Supervisor', 'Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Barang',
    to: '/barang',
    icon: 'cil-window-maximize',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Customer',
    to: '/customer',
    icon: 'cil-contact',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavGroup',
    name: 'Karyawan',
    icon: 'cil-group',
    to: '/karyawan/akun',
    auth: ['Maker', 'Approver'],
    items: [
      {
        component: 'CNavItem',
        name: 'Akun',
        to: '/karyawan/akun',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Status Login',
        to: '/karyawan/status-login',
        auth: ['Maker', 'Approver'],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Admin',
    to: '/admin',
    icon: 'cil-user',
    auth: ['Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Direktur',
    to: '/direktur',
    icon: 'cil-sitemap',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavItem',
    name: 'Metode Pembayaran',
    to: '/metode-pembayaran',
    icon: 'cil-bank',
    auth: ['Maker', 'Approver'],
  },
  {
    component: 'CNavGroup',
    name: 'Laporan',
    icon: 'cil-spreadsheet',
    to: '/laporan/delivery-order',
    auth: ['Maker', 'Approver', 'Supervisor'],
    items: [
      {
        component: 'CNavItem',
        name: 'Purchase Order',
        to: '/laporan/purchase-order',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Delivery Order',
        to: '/laporan/delivery-order',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Invoice',
        to: '/laporan/invoice',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Pembayaran',
        to: '/laporan/pembayaran',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Perbaikan Armada',
        to: '/laporan/perbaikan-armada',
        auth: ['Supervisor', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Akun Admin',
        to: '/laporan/akun-admin',
        auth: ['Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Akun Karyawan',
        to: '/laporan/akun-karyawan',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Absensi Karyawan',
        to: '/laporan/absensi-karyawan',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Customer',
        to: '/laporan/customer',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Armada',
        to: '/laporan/armada',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Barang',
        to: '/laporan/barang',
        auth: ['Maker', 'Approver'],
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Log',
    icon: 'cil-history',
    to: '/log/delivery-order',
    auth: ['Maker', 'Approver', 'Supervisor'],
    items: [
      {
        component: 'CNavItem',
        name: 'Purchase Order',
        to: '/log/purchase-order',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Delivery Order',
        to: '/log/delivery-order',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Invoice',
        to: '/log/invoice',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Pembayaran',
        to: '/log/pembayaran',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Perbaikan Armada',
        to: '/log/perbaikan-armada',
        auth: ['Supervisor', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Akun Admin',
        to: '/log/akun-admin',
        auth: ['Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Akun Karyawan',
        to: '/log/akun-karyawan',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Customer',
        to: '/log/customer',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Armada',
        to: '/log/armada',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Barang',
        to: '/log/barang',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Metode Pembayaran',
        to: '/log/metode-pembayaran',
        auth: ['Maker', 'Approver'],
      },
      {
        component: 'CNavItem',
        name: 'Direktur',
        to: '/log/direktur',
        auth: ['Maker', 'Approver'],
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Icons',
    to: '/icons',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: 'CoreUI Icons',
        to: '/icons/coreui-icons',
        badge: {
          color: 'info',
          text: 'NEW',
        },
      },
      {
        component: 'CNavItem',
        name: 'Brands',
        to: '/icons/brands',
      },
      {
        component: 'CNavItem',
        name: 'Flags',
        to: '/icons/flags',
      },
    ],
  },
  // {
  //   component: 'CNavGroup',
  //   name: 'Notifications',
  //   to: '/notifications',
  //   icon: 'cil-bell',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Modals',
  //       to: '/notifications/modals',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: 'cil-calculator',
  //   badge: {
  //     color: 'primary',
  //     text: 'NEW',
  //     shape: 'pill',
  //   },
  // },
  // {
  //   component: 'CNavTitle',
  //   name: 'Extras',
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Pages',
  //   to: '/pages',
  //   icon: 'cil-star',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Login',
  //       to: '/pages/login',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Register',
  //       to: '/pages/register',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 404',
  //       to: '/pages/404',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 500',
  //       to: '/pages/500',
  //     },
  //   ],
  // },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
