<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <!-- <CIcon custom-class-name="sidebar-brand-full" :icon="logo" :height="35" />
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon=""
        :height="35"
      /> -->
      <img class="me-1" height="35" :src="logo" alt="Logo CBF" /> PT. Citra
      Bintang Familindo
    </CSidebarBrand>
    <AppSidebarNav :notif="res" />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import logo from '@/assets/images/logo.png'
import { sygnet } from '@/assets/brand/sygnet'
import { firebaseApp } from '@/_firebaseInit'
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { getDatabase, ref as refFire, onValue } from 'firebase/database'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()

    const auth = getAuth(firebaseApp)
    // let user = auth.currentUser

    let res = ref(null)
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const db = getDatabase(firebaseApp)

        //Get User level
        const level = store.getters['auth/getData']?.Level

        if (level) {
          // Begin Listen Data
          const notif = refFire(
            db,
            `Admin/Notifikasi/${
              level === 'Supir & Mekanik' ? 'SupirMekanik' : level
            }`,
          ) //Approver,Maker,Supervisor, SuperMekanik
          onValue(notif, (snapshot) => {
            const data = snapshot.val()
            if (data) {
              res.value = data
            }
          })
        }
      } else {
        // Begin Login
        signInWithEmailAndPassword(
          auth,
          'firebase@cbfgroup.com',
          '&firebase@cbfgroup.com&',
        )
          .then(() => {
            console.log('Login Sukses')
            // console.log(userCredential)

            // user = userCredential.user
            // console.log(user.uid)
          })
          .catch(() => {
            console.log('Login Gagal')

            // console.log(error.code)
            // console.log(error.message)
          })
        // End Login
      }
    })

    return {
      res,
      logo,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
