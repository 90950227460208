<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="me-auto d-lg-none" to="/">
        <img height="48" :src="logo" alt="Logo CBF" />
      </CHeaderBrand>
      <CHeaderNav>
        <div class="d-flex flex-column text-end pe-2">
          <div style="font-size: 15px; margin-bottom: -4px" class="fw-medium">
            {{ akun?.Nama || '-' }}
          </div>
          <small>{{ akun?.Level || '-' }}</small>
        </div>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import logo from '@/assets/images/logo.png'
import { useStore } from 'vuex'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    const store = useStore()
    const akun = store.getters['auth/getData']

    return {
      akun,
      logo,
    }
  },
}
</script>
