<template>
  <div v-show="isLoading" class="loading-wrapper">
    <div class="loading">
      <CSpinner class="mb-2" color="danger" />
      <h4>Harap Tunggu</h4>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'AppLoading',
  setup() {
    const store = useStore()
    return {
      isLoading: computed(() => store.state.isLoading),
    }
  },
}
</script>

<style scoped>
.loading-wrapper {
  position: fixed;
  z-index: 9999;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
}
</style>
