import Crypto from 'crypto-js'

export default {
  namespaced: true,
  state: {
    Token: '',
    Data: '',
  },
  getters: {
    getToken(state) {
      if (state?.Token) {
        return JSON.parse(state.Token)
      }
    },
    getData(state) {
      if (state?.Data) {
        return JSON.parse(state.Data)
      }
    },
  },
  actions: {
    setItem: (state, { key, value }) => {
      const encrypt = Crypto.AES.encrypt(
        JSON.stringify(value),
        process.env.VUE_APP_API_KEY,
      ).toString()
      localStorage.setItem(key, encrypt)
    },
    getItem: (state, key) => {
      const item = localStorage.getItem(key)
      if (item) {
        const decrypt = Crypto.AES.decrypt(
          item,
          process.env.VUE_APP_API_KEY,
        ).toString(Crypto.enc.Utf8)
        return JSON.parse(decrypt)
      }
    },
    clearItem: () => {
      localStorage.clear()
    },
    setToken({ commit }) {
      const item = localStorage.getItem('_token')
      if (item) {
        const decrypt = Crypto.AES.decrypt(
          item,
          process.env.VUE_APP_API_KEY,
        ).toString(Crypto.enc.Utf8)
        commit('setToken', decrypt.toString())
      }
    },
    setData({ commit }) {
      const item = localStorage.getItem('_data')
      if (item) {
        const decrypt = Crypto.AES.decrypt(
          item,
          process.env.VUE_APP_API_KEY,
        ).toString(Crypto.enc.Utf8)
        commit('setData', decrypt.toString())
      }
    },
  },
  mutations: {
    setToken(state, payload) {
      state.Token = payload
    },
    setData(state, payload) {
      state.Data = payload
    },
  },
}
